<template>
  <div class="panel-group">
    <h3 class="panel-title panel-active"
        @click="collapse = !collapse">
      {{ $lang.$translate({ru: item.titleRu, kg: item.titleKg}) }}
      <i class="bi-chevron-down"></i>
    </h3>
    <div v-if="collapse" class="panel-body">
      <p v-html="$lang.$translate({ru: item.textRu, kg: item.textKg})"></p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FaqItem',
  props: {
    item: Object
  },
  data() {
    return {
      collapse: false
    }
  },
}
</script>
<style>
.panel-active {
  margin-bottom: 0;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.panel-title {
  padding: 15px 25px 15px 0;
  border-bottom: 1px solid #d0d0d0;
  font-size: 16px;
  color: #1f1f1f;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
.panel-title i {
  color: #1f1f1f;
  font-size: 16px;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.panel-active+.panel-body {
  display: block;
}
.panel-body {
  padding: 15px;
  display: none;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .panel-body {
    padding: 10px;
  }
}
</style>